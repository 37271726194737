// src/services/api.js
import axios from 'axios';
import { useUserStore } from "@/stores/userStore.js";
import {useConfigStore} from "@/stores/configStore";

const API_URL = process.env.VUE_APP_API_URI;

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 503) {
            const configStore = useConfigStore();
            configStore.isMaintenance = true
        }
        return Promise.reject(error);
    }
);

api.interceptors.request.use(
    async (config) => {
        const userStore = useUserStore();
        const accessToken = userStore.getAccessToken;
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const userStore = useUserStore();
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = userStore.getRefreshToken;

            if (refreshToken) {
                try {
                    const response = await axios.post(`${API_URL}token/refresh/`, {
                        refresh: refreshToken,
                    });

                    const newAccessToken = response.data.access;
                    userStore.saveAccessToken(newAccessToken);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
                    return api(originalRequest);
                } catch (refreshError) {
                    userStore.clearTokens();
                    return Promise.reject(refreshError);
                }
            }
        }

        return Promise.reject(error);
    }
);

export default api;
