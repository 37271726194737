import {defineStore} from "pinia";

export const useConfigStore = defineStore('configStore', {
    state: () => ({
        isMaintenance: false,
    }),
    getters: {
        getIsMaintenance: (state) => state.isMaintenance,
    },
    actions: {
        toggleMaintenance: (state, payload) => {
            state.isMaintenance = payload;
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'configStore',
                storage: localStorage,
            },
        ],
    },
});