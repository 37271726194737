<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card>
          <!-- Fila para título y botón -->
          <b-row class="align-items-center justify-content-between">
            <b-col>
              <h3>Listado de Juegos</h3>
            </b-col>
            <b-col class="text-right">
              <b-button variant="outline-primary" @click="goToCreateGame">
                <span>Añadir juego</span>
              </b-button>
            </b-col>
          </b-row>

          <!-- Contenido de la card -->
          <div v-if="loading" class="text-center my-5">
            <b-spinner label="Loading..."></b-spinner>
            <p>Cargando juegos...</p>
          </div>

          <div v-if="error" class="text-danger">
            {{ error }}
          </div>

          <b-list-group v-if="!loading && games.length > 0">
            <b-list-group-item v-for="game in games" :key="game.id">
              <h4>{{ game.title }}</h4>
              <p>{{ game.description }}</p>
              <b-badge v-if="game.game_type">{{ game.game_type }}</b-badge>
              <p v-if="game.min_players && game.max_players">
                Jugadores: {{ game.min_players }} - {{ game.max_players }}
              </p>
              <p v-if="game.average_playtime">
                Tiempo medio de juego: {{ game.average_playtime }} minutos
              </p>
              <b-button variant="primary" @click="viewGame(game.id)">Ver Detalles</b-button>
            </b-list-group-item>
          </b-list-group>

          <p v-if="!loading && games.length === 0">No hay juegos disponibles.</p>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import InventoryService from '@/services/inventoryService';
import { useRouter } from "vue-router";

export default {
  setup() {
    const games = ref([]);
    const loading = ref(true);
    const error = ref(null);

    const router = useRouter();

    const fetchGames = async () => {
      try {
        const response = await InventoryService.getGames();
        games.value = response.data;
      } catch (err) {
        error.value = 'No se pudieron cargar los juegos. Intenta de nuevo más tarde.';
      } finally {
        loading.value = false;
      }
    };

    const viewGame = (gameId) => {
      // Aquí podrías redirigir a una vista de detalle de juego
      console.log(`Ver detalles del juego con ID: ${gameId}`);
    };

    onMounted(() => {
      fetchGames();
    });

    const goToCreateGame = () => {
      router.push('/add-game');
    };

    return {
      games,
      loading,
      error,
      viewGame,
      goToCreateGame
    };
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.text-right {
  text-align: right;
}
</style>
