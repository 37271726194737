<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import ConfigService from '@/services/configService';
import {useConfigStore} from "@/stores/configStore";

const checking = ref(false);
const maintenanceMessage = ref("Estamos trabajando en mejoras. Vuelve más tarde.");
let intervalId;

const checkMaintenanceStatus = async () => {
  checking.value = true;
  try {
    const response = await ConfigService.checkMaintenance();
    if (!response.data?.maintenance) {
      const configStore = useConfigStore();
      configStore.isMaintenance = false;
      window.location.reload();
    }
  } catch (error) {
    console.error("Error al verificar mantenimiento:", error);
  }
  checking.value = false;
};

onMounted(() => {
  checkMaintenanceStatus();
  intervalId = setInterval(checkMaintenanceStatus, 10000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<template>
  <div class="maintenance-container">
    <div class="maintenance-content">
      <h1>🎲🚧 Mantenimiento en progreso 🚧🎲</h1>
      <p>{{ maintenanceMessage }}</p>
      <p v-if="checking">🔄 Comprobando estado...</p>
    </div>
  </div>
</template>

<style scoped>
.maintenance-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(45deg, #ff6b6b, #f94c4c);
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.maintenance-content {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  animation: bounce 1.5s infinite alternate ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}
</style>
