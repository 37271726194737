<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Registro">
          <b-alert
              ref="errorAlert"
              v-model="displayError"
              variant="danger"
              dismissible
              @dismissed="message = ''"
          >
            <div v-html="message"></div>
          </b-alert>
          <b-form @submit.prevent="register">
            <b-form-group label="Nombre de usuario:" label-for="username">
              <b-form-input
                  id="username"
                  v-model="user.username"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Nombre:" label-for="first_name">
              <b-form-input id="firstname" v-model="user.first_name" required></b-form-input>
            </b-form-group>

            <b-form-group label="Apellidos:" label-for="last_name">
              <b-form-input id="lastname" v-model="user.last_name" required></b-form-input>
            </b-form-group>

            <b-form-group label="Correo electrónico:" label-for="email">
              <b-form-input
                  type="email"
                  id="email"
                  v-model="user.email"
                  :state="touched.email ? validateEmail() : null"
                  @blur="touched.email = true"
                  required
              ></b-form-input>
              <b-form-invalid-feedback>Por favor, introduce un email válido.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Contraseña:" label-for="password">
              <b-input-group>
                <b-form-input
                    :type="passwordFieldType"
                    id="password"
                    v-model="user.password"
                    :state="touched.password ? validatePassword() : null"
                    @blur="touched.password = true"
                    required
                ></b-form-input>
                <b-button
                    @click="togglePasswordVisibility"
                    variant="outline-secondary"
                    class="d-flex align-items-center"
                >
                  <svg v-if="passwordFieldType === 'password'" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M12 4.5C7.305 4.5 3.184 7.412 1.5 12c1.684 4.588 5.805 7.5 10.5 7.5s8.816-2.912 10.5-7.5C20.816 7.412 16.695 4.5 12 4.5zm0 12c-2.485 0-4.5-2.015-4.5-4.5s2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5zm0-7c-1.381 0-2.5 1.119-2.5 2.5S10.619 14.5 12 14.5s2.5-1.119 2.5-2.5S13.381 9.5 12 9.5z"/>
                  </svg>
                </b-button>
              </b-input-group>
              <b-form-invalid-feedback>La contraseña debe tener al menos 8 caracteres.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Número de teléfono:" label-for="phone_number">
              <b-form-input
                  id="phone_number"
                  v-model="user.phone_number"
                  :state="touched.phone ? validatePhone() : null"
                  @blur="touched.phone = true"
              ></b-form-input>
              <b-form-invalid-feedback>El número de teléfono no es válido.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="DNI/NIE/Pasaporte:" label-for="dni">
              <b-form-input
                  id="dni"
                  v-model="user.dni"
                  :state="touched.dni ? validateDNI() : null"
                  @blur="touched.dni = true"
                  required
              ></b-form-input>
              <b-form-invalid-feedback>Introduce un DNI, NIE o pasaporte válido.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Dirección:" label-for="address">
              <b-form-input type="text" id="address" v-model="user.address" required></b-form-input>
            </b-form-group>

            <b-form-group label="Usuario de Telegram:" label-for="telegram_username">
              <b-form-input
                  id="telegram_username"
                  v-model="user.telegram_username"
                  :state="touched.telegram_username ? validateTelegramUsername() : null"
                  @blur="touched.telegram_username = true"
                  required
              ></b-form-input>
              <b-form-invalid-feedback>El usuario de Telegram debe comenzar con @.</b-form-invalid-feedback>
            </b-form-group>

            <PrivacyTermsDataComponent class="privacy-data" @update:accepted-terms="updateAcceptedTerms" identifier="privacyTerms" title="Privacidad de datos" />
            <PrivacyTermsImageComponent @update:accepted-terms="updateAcceptedImageTerms" identifier="privacyImageTerms" title="Privacidad de imagen" />

            <b-button class="register" type="submit" variant="primary" :disabled="!user.privacy_accepted || loading">
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner> Registrando...
              </span>
              <span v-else>Registrar</span>
            </b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/authService';
import PrivacyTermsDataComponent from "@/components/register/PrivacyTermsDataComponent.vue";
import PrivacyTermsImageComponent from "@/components/register/PrivacyTermsImageComponent.vue";

export default {
  components: {
    PrivacyTermsDataComponent,
    PrivacyTermsImageComponent
  },
  setup() {
    const user = ref({
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phone_number: '',
      dni: '',
      address: '',
      telegram_username: '',
      privacy_accepted: false,
      privacy_image_accepted: false
    });

    const touched = ref({
      password: false,
      email: false,
      phone: false,
      dni: false,
      telegram_username: false
    });

    const displayError = ref(false);
    const message = ref('');
    const loading = ref(false);
    const passwordFieldType = ref('password');
    const router = useRouter();

    const validatePassword = () => user.value.password.length >= 8;
    const validateEmail = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.value.email);
    const validatePhone = () => /^(\+\d{1,3}\s?)?\d{7,15}$/.test(user.value.phone_number);
    const validateDNI = () => /^[0-9]{8}[A-Z]|^[XYZ][0-9]{7}[A-Z]|^[A-Z]{3}[0-9]{6,9}$/.test(user.value.dni);
    const validateTelegramUsername = () => /^@[\w\d_]+$/.test(user.value.telegram_username);

    const register = async () => {
      if (!validateEmail() || !validatePhone() || !validateDNI() || !validateTelegramUsername() || !validatePassword()) {
        displayError.value = true;
        message.value = 'Alguno de los campos contiene errores. Revísalos y vuelve a intentarlo.';
        nextTick(() => {
          scrollToAlert();
        });
        return;
      }
      loading.value = true;
      try {
        await AuthService.register(user.value);
        router.push('/login');
      } catch (error) {
        displayError.value = true;
        message.value = 'Ocurrió un error al registrar. Por favor, inténtelo de nuevo.';
        nextTick(() => {
          scrollToAlert();
        });
      } finally {
        loading.value = false;
      }
    };

    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    };

    const updateAcceptedTerms = (value) => {
      user.value.privacy_accepted = value;
    };

    const updateAcceptedImageTerms = (value) => {
      user.value.privacy_image_accepted = value;
    };

    const scrollToAlert = () => {
      if (displayError.value) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    return {
      user,
      touched,
      displayError,
      message,
      loading,
      passwordFieldType,
      validatePassword,
      validateEmail,
      validatePhone,
      validateDNI,
      validateTelegramUsername,
      register,
      togglePasswordVisibility,
      updateAcceptedTerms,
      updateAcceptedImageTerms
    };
  }
};
</script>
