// src/services/authService.js
import api from './api';
import { useUserStore } from "@/stores/userStore.js";

class AuthService {
    login(user) {
        return api.post('login/', {
            username: user.username,
            password: user.password,
        }).then(response => {
            if (response.data.access) {
                const userStore = useUserStore();
                userStore.saveUserTokens(response.data);
            }
            return response.data;
        });
    }

    logout() {
        const userStore = useUserStore();
        userStore.cleanLoggedInUser();
    }

    register(user) {
        return api.post('register/', {
            username: user.username,
            email: user.email,
            password: user.password,
            phone_number: user.phone_number,
            dni: user.dni,
            first_name: user.first_name,
            last_name: user.last_name,
            telegram_username: user.telegram_username,
            address: user.address,
            privacy_accepted: user.privacy_accepted,
            privacy_image_accepted: user.privacy_image_accepted,
        });
    }

    getCurrentUser() {
        const userStore = useUserStore();
        return userStore.isUserLoggedIn;
    }

    createStripeCustomerPortal() {
        return api.post('create-stripe-portal/');
    }

    createStripeCheckoutSession(priceId, couponId = null) {
        const data = { price_id: priceId };
        if (couponId) {
            data.coupon_id = couponId;
        }
        return api.post('create-stripe-checkout/', data);
    }

    getUserProfile(userId) {
        if (userId !== undefined) {
            return api.get('profile/' + userId);
        }
        return api.get('profile/');
    }

    updateUserProfile(data) {
        return api.put('profile/', data);
    }

    listUsers() {
        return api.get('users/');
    }

    requestPasswordReset(data) {
        return api.post('request-reset-password/', data);
    }

    resetPassword(data) {
        return api.patch('reset-password/', data);
    }

    exportUsersCSV() {
        api.get("export-users-csv/", { responseType: "blob" })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "usuarios.csv");
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error("Error al descargar CSV:", error);
            });
    }
}

export default new AuthService();
