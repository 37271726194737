<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Suscripción">
          <b-form @submit.prevent="subscribe">
            <b-form-group label="Elegir mensualidad" label-for="plan">
              <b-form-select v-model="selectedPlan" :options="plans" id="plan"></b-form-select>
            </b-form-group>
            <b-form-group label="Cupón (opcional)" label-for="coupon">
              <b-form-input id="coupon" v-model="coupon"></b-form-input>
            </b-form-group>
            <b-button class="btn-text" type="submit" variant="primary" :disabled="loading">
              <b-spinner small v-if="loading" class="btn-text" label="Processing..."></b-spinner>
              <span v-if="!loading" class="btn-text">Suscribirse</span>
              <span v-if="loading" class="btn-text">Cargando suscripción...</span>
            </b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AuthService from '@/services/authService';

export default {
  data() {
    return {
      selectedPlan: null,
      coupon: '',
      plans: [],
      loading: false,
      subscribeButtonDisabled: false
    };
  },
  mounted() {
    if(process.env.VUE_APP_DEBUG === '1') {
      this.plans = JSON.parse(process.env.VUE_APP_PLANS_TEST);
    } else {
      this.plans = JSON.parse(process.env.VUE_APP_PLANS);
    }
  },
  methods: {
    subscribe() {
      this.loading = true; // Start loading state
      AuthService.createStripeCheckoutSession(this.selectedPlan, this.coupon).then(
        response => {
          window.location.href = response.data.url;
        },
        error => {
          console.error(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style>
.btn-text {
  margin-top: 15px;
}
</style>
