<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card title="Listado de usuarios">
          <ExportUsersButtonComponent/>
          <b-table
              striped
              hover
              :fields="fields"
              :items="users"
          >
            <!-- A custom formatted column -->
            <template #cell(username)="data">
              <b-link :href="'/profile/' + data.item.id">{{ data.item.username }}</b-link>
            </template>
            <template #cell(actions)="data">
              <b-link :href="adminURL + 'vlusers/vluser/' + data.item.id + '/change/'">Editar en panel</b-link>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AuthService from '@/services/authService';
import ExportUsersButtonComponent from "@/components/ExportUsersButtonComponent.vue";

export default {
  components: {ExportUsersButtonComponent},
  data() {
    return {
      users: [],
      fields: [],
      sortBy: 'username',
      sortDesc: false,
      adminURL: null,
    };
  },
  created() {
    this.adminURL = process.env.VUE_APP_API_ADMIN;
    this.fields = [
      { key: 'id', label: 'ID'},
      { key: 'username', label: 'Nombre de usuario' },
      { key: 'email', label: 'Email' },
      { key: 'phone_number', label: 'Teléfono' },
      { key: 'dni', label: 'DNI' },
      'actions'
    ]

    AuthService.listUsers().then(
        response => {
          this.users = response.data;
        },
        error => {
          console.error(error);
        }
    );
  }
};
</script>
