<template>
  <b-container>
    <b-row>
      <b-col md="8" offset-md="2">
        <b-card title="Añadir Nuevo Juego" class="mb-4">
          <div v-if="error" class="text-danger">{{ error }}</div>

          <!-- Sección 1: Barra de búsqueda para juegos en BGG -->
          <b-card class="mb-3" header="Buscar en BGG">
            <b-form-group label="Buscar en BGG">
              <b-input-group>
                <b-form-input v-model="bggQuery" placeholder="Ingrese el nombre del juego"></b-form-input>
                <b-button variant="primary" @click="searchBGGGames">Buscar</b-button>
              </b-input-group>
              <b-list-group v-if="bggGames.length > 0">
                <b-list-group-item
                    v-for="game in bggGames"
                    :key="game.id"
                    @click="selectBGGGame(game)"
                    style="cursor: pointer;"
                >
                  {{ game.name.value }}
                </b-list-group-item>
              </b-list-group>
            </b-form-group>
          </b-card>

          <!-- Sección 2: Detalles del juego -->
          <b-card class="mb-3" header="Detalles del Juego">
            <b-form-group label="Título" label-for="title">
              <b-form-input
                  id="title"
                  v-model="game.title"
                  required
                  placeholder="Ingrese el título del juego"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Descripción" label-for="description">
              <b-form-textarea
                  id="description"
                  v-model="game.description"
                  placeholder="Ingrese una descripción del juego"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label="Tipo de Juego" label-for="gameType">
              <b-form-select
                  id="gameType"
                  v-model="game.game_type"
                  :options="gameTypes"
                  required
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Idioma" label-for="language">
              <b-form-input
                  id="language"
                  v-model="game.language"
                  placeholder="Ingrese el idioma del juego"
              ></b-form-input>
            </b-form-group>

          </b-card>

          <!-- Sección 3: Especificaciones adicionales -->
          <b-card class="mb-3" header="Especificaciones (selecciona primero el tipo de juego)">
            <div v-if="game.game_type === 'boardgame'">
              <b-form-group label="Mínimo de Jugadores" label-for="minPlayers">
                <b-form-input
                    id="minPlayers"
                    type="number"
                    v-model="game.min_players"
                    placeholder="Ingrese el número mínimo de jugadores"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Máximo de Jugadores" label-for="maxPlayers">
                <b-form-input
                    id="maxPlayers"
                    type="number"
                    v-model="game.max_players"
                    placeholder="Ingrese el número máximo de jugadores"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Tiempo Medio de Juego (minutos)" label-for="avgPlaytime">
                <b-form-input
                    id="avgPlaytime"
                    type="number"
                    v-model="game.avg_playtime"
                    placeholder="Ingrese el tiempo medio de juego en minutos"
                ></b-form-input>
              </b-form-group>
            </div>
          </b-card>

          <!-- Sección 4: Código QR -->
          <b-card class="mb-3" header="Código QR">
            <b-form-group label="BGG ID" label-for="bggId">
              <b-form-input
                  id="bggId"
                  v-model="game.bgg_id"
                  placeholder="ID del juego en BoardGameGeek"
                  readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Código QR (Opcional)">
              <b-button variant="secondary" @click="toggleQrScanner">
                {{ qrScannerActive ? 'Cerrar Lector QR' : 'Activar Lector QR' }}
              </b-button>
              <div v-if="qrScannerActive" class="mt-3">
                <qrcode-stream @detect="onDetectCode" @init="onInit" @error="onQrError"></qrcode-stream>
                <div v-if="qrError" class="text-danger">{{ qrError }}</div>
              </div>
              <div v-if="game.qr_code">
                <b-badge variant="success">Código QR asignado: {{ game.qr_code }}</b-badge>
              </div>
            </b-form-group>
          </b-card>

          <!-- Sección 5: Lugar de almacenamiento -->
          <b-card class="mb-3" header="Lugar de Almacenamiento">
            <b-form-group label="Estantería" label-for="shelf">
              <b-form-select
                  id="shelf"
                  v-model="game.shelf"
                  :options="shelves"
                  placeholder="Seleccione una estantería"
              ></b-form-select>
            </b-form-group>

            <b-form-group label="Fila" label-for="row">
              <b-form-select
                  id="row"
                  v-model="game.row"
                  :options="rows"
                  placeholder="Seleccione una fila"
              ></b-form-select>
            </b-form-group>

            <b-form-group label="Columna" label-for="column">
              <b-form-select
                  id="column"
                  v-model="game.column"
                  :options="columns"
                  placeholder="Seleccione una columna"
              ></b-form-select>
            </b-form-group>
          </b-card>

          <b-button type="submit" variant="primary">Añadir Juego</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref } from 'vue';
import InventoryService from '@/services/inventoryService';
import BGGService from '@/services/bggService';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  components: {
    QrcodeStream,
  },
  setup() {
    const game = ref({
      title: '',
      description: '',
      game_type: '',
      min_players: null,
      max_players: null,
      avg_playtime: null,
      language: '',
      qr_code: '',
      bgg_id: null,
      shelf: '',
      row: '',
      column: '',
    });

    const gameTypes = [
      { value: 'boardgame', text: 'Mesa' },
      { value: 'roleplay', text: 'Rol' },
      { value: 'other', text: 'Otro' },
    ];

    const shelves = ref([{ value: 'A', text: 'A' }, { value: 'B', text: 'B' }]);
    const rows = ref([{ value: 1, text: '1' }, { value: 2, text: '2' }]);
    const columns = ref([{ value: 1, text: '1' }, { value: 2, text: '2' }]);

    const bggQuery = ref('');
    const bggGames = ref([]);
    const error = ref(null);
    const qrError = ref(null);
    const qrScannerActive = ref(false);

    const submitGame = async () => {
      try {
        await InventoryService.addGame(game.value);
        alert('Juego añadido correctamente');
      } catch (err) {
        error.value = 'No se pudo añadir el juego. Intenta de nuevo más tarde.';
      }
    };

    const searchBGGGames = async () => {
      try {
        bggGames.value = await BGGService.searchGames(bggQuery.value);
      } catch (err) {
        error.value = 'No se pudieron buscar juegos en BGG. Intenta de nuevo más tarde.';
      }
    };

    const selectBGGGame = async (bggGame) => {
      try {
        game.value.bgg_id = bggGame.id;
        const gameDetails = await BGGService.getGameDetails(bggGame.id);
        const primaryName = Array.isArray(gameDetails.name)
            ? gameDetails.name.find((name) => name.type === "primary")?.value
            : gameDetails.name.value;
        game.value.title = primaryName || 'Sin título';
        game.value.description = gameDetails.description || `Publicado en ${bggGame.yearpublished?.value || 'N/A'}`;
        game.value.game_type = 'boardgame';
        game.value.min_players = gameDetails.minplayers?.value || null;
        game.value.max_players = gameDetails.maxplayers?.value || null;
        game.value.avg_playtime = gameDetails.playingtime?.value || null;
        bggGames.value = [];
      } catch (err) {
        error.value = 'No se pudo seleccionar el juego. Intenta de nuevo.';
        console.error(err);
      }
    };

    const onQrError = (err) => {
      qrError.value = 'No se pudo leer el código QR. Por favor, intenta de nuevo.';
      console.error(err);
    };

    const onInit = (promise) => {
      promise.catch((err) => {
        qrError.value = 'No se pudo acceder a la cámara. Asegúrate de tener los permisos correctos.';
        console.error(err);
      });
    };

    const onDetectCode = (detectedCodes) => {
      if (detectedCodes.length > 0) {
        game.value.qr_code = detectedCodes[0]?.rawValue;
        qrError.value = null;
        qrScannerActive.value = false;
      }
    };

    const toggleQrScanner = () => {
      qrScannerActive.value = !qrScannerActive.value;
    };

    return {
      game,
      gameTypes,
      shelves,
      rows,
      columns,
      bggQuery,
      bggGames,
      error,
      qrError,
      qrScannerActive,
      submitGame,
      searchBGGGames,
      selectBGGGame,
      onQrError,
      onInit,
      toggleQrScanner,
      onDetectCode,
    };
  },
};
</script>

<style scoped>
.text-danger {
  color: red;
}
</style>
