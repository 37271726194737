<script setup>
import { ref, onMounted } from "vue";
import FileService from "@/services/fileService";

const files = ref([]);
const loading = ref(true);
const errorMessage = ref("");

const fetchFiles = () => {
  loading.value = true;
  FileService.listFiles()
      .then(response => {
        files.value = response.data;
      })
      .catch(error => {
        console.error("Error al obtener archivos:", error);
        errorMessage.value = "No se pudieron cargar los archivos.";
      })
      .finally(() => {
        loading.value = false;
      });
};

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  const icons = {
    pdf: "📕",
    doc: "📄",
    docx: "📄",
    xls: "📊",
    xlsx: "📊",
    jpg: "🖼️",
    jpeg: "🖼️",
    png: "🖼️",
    gif: "🖼️",
    mp4: "🎥",
    avi: "🎬",
    mkv: "🎞️",
    zip: "📦",
    rar: "📦",
  };
  return icons[extension] || "📁"; // Icono por defecto
};

onMounted(fetchFiles);
</script>

<template>
  <div class="file-list-container">
    <h2>📂 Archivos Disponibles</h2>

    <div v-if="loading" class="loading">Cargando archivos...</div>
    <div v-else-if="errorMessage" class="error">{{ errorMessage }}</div>
    <ul v-else class="file-list">
      <li v-for="file in files" :key="file.url" class="file-item">
        <span class="file-icon">{{ getFileIcon(file.url) }}</span>
        <div class="file-info">
          <a :href="file.url" target="_blank" class="file-name">{{ file.name }}</a>
          <div class="file-meta">
            <span v-if="file.is_public" class="file-tag public">🌍 Público</span>
            <span v-else-if="file.is_for_members" class="file-tag members">🔒 Solo socios</span>
            <span v-else class="file-tag private">🔐 Privado</span>
            <span class="file-date">📅 {{ new Date(file.uploaded_at).toLocaleDateString() }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.file-list-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
}

.loading, .error {
  text-align: center;
  font-weight: bold;
  color: #d9534f;
}

.file-list {
  list-style: none;
  padding: 0;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background 0.2s;
}

.file-item:hover {
  background: #eee;
}

.file-icon {
  font-size: 24px;
  margin-right: 15px;
}

.file-info {
  flex-grow: 1;
}

.file-name {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

.file-name:hover {
  text-decoration: underline;
}

.file-meta {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.file-tag {
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 5px;
}

.public {
  background: #28a745;
  color: white;
}

.members {
  background: #ffc107;
  color: black;
}

.private {
  background: #dc3545;
  color: white;
}

.file-date {
  color: #777;
}
</style>
