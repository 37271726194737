<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/userStore.js";
import AuthService from "@/services/authService";

const userStore = useUserStore();
const isSuperuser = computed(() => userStore.loggedInUser?.is_superuser || false);

const exportCSV = () => {
  AuthService.exportUsersCSV();
};
</script>

<template>
  <button v-if="isSuperuser" @click="exportCSV" class="export-button">
    📥 Exportar Usuarios CSV
  </button>
</template>

<style scoped>
.export-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background 0.3s ease;
}
.export-button:hover {
  background-color: #45a049;
}
</style>
